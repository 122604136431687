<template>
  <div>
    <v-app-bar app clipped-left :color="color" dense dark>
      <!-- <v-app-bar-nav-icon v-if="!permanent" @click.stop="drawer = !drawer" /> -->
      <v-spacer />
      <v-toolbar-title>
        <!-- <v-btn text to="/"> -->
          <a href="/"><v-img class="d-none d-sm-block" :src="logoUrl" width="180px" alt="wwcalc"/></a>
          <a href="/"><v-img class="d-flex d-sm-none" :src="logoUrl" width="80px" alt="wwcalc"/></a>
        <!-- </v-btn> -->
      </v-toolbar-title>
      <v-spacer />
      <v-btn text @click="redirectFunc()">₡ {{this.balanceFormatted}}</v-btn>
      <v-btn text @click="$router.push({ name: 'support'})">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
      <!-- <v-switch v-model="helpInfo" color="white" value="On" hide-details></v-switch> -->
    </v-app-bar>
    <v-navigation-drawer app :mini-variant="$vuetify.breakpoint.smAndDown" :expand-on-hover="$vuetify.breakpoint.smAndDown" permanent clipped width="300" :color="color" dark>
      <v-list dense nav class="py-0">
        <v-list-item v-if="profile" :class="'px-0'" @click="$router.push({ name: 'profile' })">
          <v-list-item-avatar>
            <v-icon>mdi-account-circle</v-icon>
            <!-- <img :src="profile.avatar"> -->
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{profile.first_name}}</v-list-item-title>
            <v-list-item-subtitle>{{profile.email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="$router.push({ name: 'new_railway' })">
          <v-list-item-icon>
            <v-img lazy-src="/img/railway.jpg" max-width="30" src="/img/railway.jpg"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.selectedLanguage['navigation_title_name_1'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$router.push({ name: 'new_roadway' })">
          <v-list-item-icon>
            <v-img lazy-src="/img/roadway.jpg" max-width="30" src="/img/roadway.jpg"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.selectedLanguage['navigation_title_name_2'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item disabled @click="$router.push({ name: 'new_hidrology' })">
          <v-list-item-icon>
            <v-img lazy-src="/img/hidrology.jpg" max-width="30" src="/img/hidrology.jpg"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.selectedLanguage['navigation_title_name_3'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item disabled @click="$router.push({ name: 'new_powerline' })">
          <v-list-item-icon>
            <v-img lazy-src="/img/power_line.jpg" max-width="30" src="/img/power_line.jpg"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.selectedLanguage['navigation_title_name_4'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item disabled @click="$router.push({ name: 'new_duct' })">
          <v-list-item-icon>
            <v-img lazy-src="/img/duct.jpg" max-width="30" src="/img/duct.jpg"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.selectedLanguage['navigation_title_name_5'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item disabled @click="$router.push({ name: 'new_dam' })">
          <v-list-item-icon>
            <v-img lazy-src="/img/dam.jpg" max-width="30" src="/img/dam.jpg"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.selectedLanguage['navigation_title_name_6'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item disabled @click="$router.push({ name: 'new_riparianforest' })">
          <v-list-item-icon>
            <v-img lazy-src="/img/riparian_forest.jpg" max-width="30" src="/img/riparian_forest.jpg"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.selectedLanguage['navigation_title_name_7'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item disabled @click="$router.push({ name: 'new_allotment' })">
          <v-list-item-icon>
            <v-img lazy-src="/img/allotment.jpg" max-width="30" src="/img/allotment.jpg"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ this.selectedLanguage['navigation_title_name_8'] }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-list-item link @click="logoutClick()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { portuguese } from '../languages/pt-br.js'
  import { english } from '../languages/en'

  export default {
    name: 'Navigation',
    mounted: async function(){
      this.getMonetize()
    },
    computed: {
      ...mapGetters(['profile','balanceFormatted']),
      selectedLanguage() {
        if(this.profile != undefined){
          if (this.profile['language'] === 'en') {
            return english;
          } else {
            return portuguese;
          }
        } else {
          return portuguese;
        }
        }
    },
    data: () => ({
      navBarPinned: false,
      closed: true,
      color: '#0A7373',
      logoUrl: '/img/logo.png',
      helpInfo: null
    }),
    methods: {
      redirectFunc(){
        if(process.env.VUE_APP_VAR_SELECT== 'prd'){
          this.$router.push({ name: 'billingMercadoPago' })
        } else {
          this.$router.push({ name: 'billing' })
        }
      },
      ...mapActions(['logout', 'setHelper','getMonetize']),
      async logoutClick(){
        this.logout()
        this.$router.push({ name: 'login' })
      }
    },
    watch:{
      helpInfo: {
        handler(){
          window.localStorage.setItem('Helper',this.helpInfo)
          this.setHelper(this.helpInfo)
        }
      }
    }
  }
</script>
